.selected-job-container {
  height: 90vh;
  position: sticky;
  position: -webkit-sticky;
  top: 1rem;
}

@media (min-width: 768px) {
  .selected-job-container::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 0.438rem;
  }

  .selected-job-container::-webkit-scrollbar-thumb {
    border-radius: 0.25rem;
    background-color: rgba(40, 40, 40, .5);
    box-shadow: 0 0 0.063rem rgba(255, 255, 255, .5);
  }
}

@media (max-width: 767.98px) {
  .jobs-feed-container {
    height: 64vh;
  }
}

.job-card {
  min-height: 12.5rem;
}

.job-card img.business-logo {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 0.25rem;
}

.job-view img.business-logo {
  width: 3.125rem;
  height: 3.125rem;
  border-radius: 0.25rem;
}

.job-active {
  border: 0.125rem solid var(--mainSecondaryColor);
}

.card-title {
  font-weight: 600;
  font-size: 1.375rem;
}

.card-title:hover {
  text-decoration: underline;
}

.card-text {
  font-weight: 500;
  font-size: 0.875rem;
}

.card-footer {
  background-color: transparent;
  border: none;
}

.job-title {
  font-size: xx-large;
}

.job-subtitle {
  font-size: 1.125rem;
}

.job-inner-title {
  font-weight: 700;
  font-size: large;
}

.job-inner-subtitle {
  font-size: medium;
  font-weight: 700;
}

.job-inner-body {
  font-weight: 500;
}

.job-links {
  text-align: right;
  padding-right: 1.25rem;
  margin-bottom: 0.313rem;
}

.job-links .share-btn {
  width: 9.3rem;
  margin-bottom: 0.313rem;
}

.job-links .apply-btn {
  width: 9.3rem;
  margin-left: 0.313rem;
  margin-bottom: 0.313rem;
}

.selected-filter{
  background: var(--mainPrimaryColor);
  color: #ffffff;
  width: 100%;
  border-radius: 0.313rem;
  padding: 0.375rem 0.688rem;
  position: relative;
}

.cross-button{
  position: absolute;
  right: 0.938rem;
  cursor: pointer;
}

.job-bnefit-block {
  border: solid 0.063rem grey;
  padding: 0.313rem 0.938rem;
  border-radius: 0.313rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0rem;
  font-weight: 500;
}
.magnet-referrel-bonus-image {
  width: 1.3rem;
  margin-right: 0.5rem;
}

@media (max-width: 575.98px) {
  .job-links {
    text-align: left;
    margin-top: 0.625rem;
    margin-bottom: 1.125rem;
  }
}

@media (max-width: 375px) {
  .job-links .share-btn {
    margin-right: 0.313rem;
  }

  .job-links .apply-btn {
    margin-left: 0rem;
  }
}