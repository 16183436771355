.vh-40-container {
  min-height: 40vh;
  padding: 2rem;
}

.vh-30-container {
  min-height: 30vh;
  padding: 2rem;
}

.vh-65-container {
  min-height: 65vh;
  padding: 0;
}

.vh-72-container {
  max-height: 72vh;
}

.home-container .orange-magnet-img { width: 8rem; }
.how-it-works-container .orange-magnet-img { width: 7rem; }
.employers-how-to-container .orange-magnet-img { width: 7rem; }

.how-it-works-container .map-marker-img { width: 4.5rem; }
.how-it-works-container .chat-bubble-img { width: 5rem; }
.how-it-works-container .bullseye-img { width: 5rem; }

.employers-how-to-container .paper-airplane-step1-img { width: 4.4rem; }
.employers-how-to-container .chat-bubble-chart-step2-img { width: 5rem; }
.employers-how-to-container .bullseye-step3-img { width: 4.6rem; }

@media (min-width: 767px) {
  .vh-94-md-container {
    min-height: 94vh;
    padding: 2rem;
  }

  .vh-84-md-container {
    min-height: 84vh;
    padding: 2rem;
  }
}