.table tr {
  height: 3.5rem;
  vertical-align: middle;
}

.table>:not(:last-child)>:last-child>* {
  border-bottom-color: inherit;
}

.table-scrollable {
  height: 78vh;
}

.table-sticky-header table th {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: var(--primaryLightColor);
  z-index: 1;
}

.table-sticky-header table th::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-bottom: 1px solid var(--primaryBorderColor);
  border-bottom-color: inherit;
}

.table-sticky-header .table>:not(:last-child)>:last-child>* {
  border-bottom-width: 0;
}