.footer {
  font-size: 90%;
  color: var(--secondaryDarkColor);
}

.footer .nav-link.disabled {
  color: var(--primaryDarkColor);
}

.footer .nav-link {
  color: var(--secondaryDarkColor);
}

.footer .nav-link:hover {
  text-decoration: underline;
}