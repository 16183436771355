.form-container {
  width: 42%;
  border: 1px solid var(--primaryBorderColor);
  border-radius: 10px;
  padding: 2rem;
}

@media (max-width: 767.98px) {
  .form-container {
    width: 100%;
    border: none;
  }
}

.form-label.required:after, .form-floating.required label:after {
  content: "*";
  color: red;
}

.floating-label-sm {
  font-size: .875rem;
}

.file-field-background {
  background-color: #fff !important;
}

.input-file-hover:hover .input-group-text {
  background-color: #dde0e3;
  cursor: pointer;
  transition: all .5s;
}

.input-file-hover:hover .file-name-field {
  cursor: pointer;
}
