.organizations-search-form {
  width: 100%;
}

.organizations-search-form input.form-control {
  border-radius: 2rem;
  padding-left: 1.5rem;
}

.organizations-search-form label {
  padding-left: 1.5rem;
}

.organizations-search-form .dropdown-item {
  white-space: normal;
}

.organizations-search-form .dropdown-menu {
  border-radius: 2rem;
  margin-top: .25rem;
}