.image-upload-input .preview.banner {
  width: 850px;
  height: 150px;
  max-width: 100%;
  object-fit: cover;
  border-radius: 0.50rem;
}

.image-upload-input .preview.active:hover {
  filter: brightness(80%);
  cursor: pointer;
}

.image-upload-input .preview.logo {
  width: 6rem;
  height: 6rem;
  border-radius: 0.25rem;
}

@media (max-width: 767.98px) {
  .image-upload-input .preview.banner {
    width: 90%;
    height: 150px;
    object-fit: cover;
    border-radius: 0.50rem;
  }
}