.sm-icon-img {
  width: 3rem;
  height: 3rem;
  border-radius: 0.25rem;
}

.md-icon-img {
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 0.25rem;
}

@media (max-width: 767.98px) {
  .sm-icon-img {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 0.25rem;
  }

  .md-icon-img {
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 0.25rem;
  }
}