/*
  The inline-icon class expects the following two form input structures for proper spacing.

  FloatingLabel example:
  <FloatingLabel controlId="password" label="Password">
    <Form.Control type=password placeholder="Password" value={formData.password} onChange={e => setFormData({...formData, password: e.target.value})}/>
    <i className="inline-icon icon-btn p-2 fa fa-eye-slash"/>
  </FloatingLabel>

  Form Group example:
  <Form.Group className="input-with-icon">
    <Form.Control className="form-control-sm" type="text" placeholder="Search for a job" value={search} onChange={e => setSearch(e.target.value)}/>
    <i className="inline-icon icon-btn p-2 fa fa-search"/>
  </Form.Group>
*/

.inline-icon {
  position: absolute;
  top: 1.5em;
  right: 0%;
  transform: translate(-50%, -50%);
  color: var(--secondaryDarkColor);
}

.form-floating > .inline-icon {
  top: 1.9em;
}

.is-invalid.password-input~.inline-icon {
  right: 2%;
}

.icon-btn {
  cursor: pointer;
}

.icon-as-link {
  cursor: pointer;
  color: var(--mainPrimaryColor);
}

.icon-as-link:hover {
  color: var(--mainSecondaryColor);
}

.icon-as-link.loading {
  cursor: wait;
  color: var(--secondaryDarkColor);
}

.input-with-inline-icon {
  position: relative;
}

.input-with-inline-icon .form-control {
  height: 3rem;
}