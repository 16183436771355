.reward-sources-container {
  height: 60vh;
  overflow: auto;
}

.reward-source-entry-link {
  color: var(--primaryDarkColor);
  text-decoration: none;
}

.reward-source-entry-link:hover {
  filter: brightness(0.9);
  cursor: pointer;
}

@media (max-width: 767.98px) {
  .reward-sources-container {
    height: 80vh;
    overflow: visible;
  }
}