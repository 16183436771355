.employer-profile-header .logo-container img.logo {
  width: 5rem;
  height: 5rem;
  border: 3px solid var(--lightBgColor);
  border-radius: 0.25rem;
  background-color: var(--lightBgColor);
}

.employer-profile-header .logo-container {
  margin-top: -50px;
}

.employer-profile-header div.banner {
  width: 850px;
  height: 150px;
  border-radius: 0.50rem;
  background-position: center center;
  background-size: cover;
}
