html {
  /* buttons/links/navbar/accents colors */
  --mainPrimaryColor: #2EB2BF; /* blue/green */
  --mainSecondaryColor: #F9A908; /* orange */
  --mainTertiaryColor: #1A656D; /* teal */

  /* gray/white colors */
  --primaryDarkColor: #1A1A1D;
  --secondaryDarkColor: #565455;
  --primaryLightColor: #FFFFFF;
  --primaryBorderColor: #CCCCCC;

  /* background colors */
  --lightBgColor: #FFFFFF; /* home page action bar */
  --mediumBgColor: #9FE8FFFF; /* home page right column */
  --darkBgColor: #2EB2BF; /* home page left column */

  /* admin colors */
  --mainAdminColor: #008000;

  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
  font-weight: 200;
  background-color: var(--primaryLightColor);
  color: var(--primaryDarkColor);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body, #root{
  height: 100vh;
}

.cursive {
  font-family: 'Lobster', sans-serif;
}

#root {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.light-background {
  background-color: var(--lightBgColor);
  color: var(--secondaryDarkColor);
}

.left-half-abstract-magnet {
  background-image: url("/src/images/abstract-magnet.png");
  background-repeat: no-repeat;
  background-position-x: 65%;
  background-attachment: fixed;
  background-size: 38.5rem;
}

.right-half-abstract-magnet {
  background-image: url("/src/images/abstract-magnet.png");
  background-repeat: no-repeat;
  background-position-x: 65%;
  background-attachment: fixed;
  background-size: 38.5rem;
}

.medium-background {
  background-color: var(--mediumBgColor);
}

.dark-background {
  background-color: var(--darkBgColor);
}

.primary-mw-bg {
  background-color: var(--mainPrimaryColor);
}

.error-text {
  color: red;
}

.primary-mw-text {
  color: var(--mainPrimaryColor);
}

.secondary-mw-text {
  color: var(--mainSecondaryColor);
}

.tertiary-mw-text {
  color: var(--mainTertiaryColor);
}

.rich-text-block {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.mw-text-small {
  font-size: 0.8rem;
}

@media (max-width: 767.98px) {
  input[type="text"],
  input[type="password"],
  input[type="date"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="month"],
  input[type="week"],
  input[type="email"],
  input[type="number"],
  input[type="search"],
  input[type="tel"],
  input[type="time"],
  input[type="url"],
  textarea {
    font-size: 1rem;
  }

  .left-half-abstract-magnet {
    background-image: url("/src/images/abstract-magnet.png");
    background-repeat: no-repeat;
    background-position: center 18rem;
    background-attachment: scroll;
    background-size: 100%;
  }

  .right-half-abstract-magnet {
    background-image: url("/src/images/abstract-magnet.png");
    background-repeat: no-repeat;
    background-position: center -10rem;
    background-attachment: scroll;
    background-size: 100%;
  }
}

.warning-text {
  color: #FF5F15
}

.standard-checkbox .form-check-input:checked {
  background-color: var(--mainPrimaryColor);
}

@import "css/generic/Buttons.css";
@import "css/generic/Containers.css";
@import "css/generic/Icons.css";
@import "css/generic/Images.css";
@import "css/generic/Links.css";
@import "css/generic/Text.css";
@import "css/generic/Tables.css";
@import "css/generic/Tabs.css";
@import "css/generic/Forms.css";
@import "css/generic/PaymentElements.css";