header .navbar {
  background-color: var(--primaryLightColor);
  border-bottom: 1px solid var(--primaryBorderColor);
}

header .header-user-dropdown button, header .header-user-dropdown button:focus {
  background-color: transparent;
  border: none;
  color: var(--secondaryDarkColor);
}

header .header-user-dropdown button:hover, header .header-user-dropdown button:first-child:active,
header.use-light-buttons .header-user-dropdown button:hover, header.use-light-buttons .header-user-dropdown button:first-child:active {
  background-color: transparent;
  color: var(--mainSecondaryColor);
}

header.use-light-buttons .header-user-dropdown button, header.use-light-buttons .header-user-dropdown button:focus {
  color: var(--primaryLightColor);
}

.fixed-top-header .navbar {
  background-color: transparent;
  border-bottom: 0px;
}

header .brand {
  height: 2.7rem;
}

header .nav-btn {
  width: 8rem;
}

header .navbar-toggler {
  border: none !important;
}

@media (max-width: 767.98px) {
  header .nav-btn {width: 100%;}
  header .standard-btn {border-radius: 0;}
  header .navbar-brand {margin: 0;}
  header .header-user-dropdown {padding: 0;}

  header .brand {
    height: 2rem;
  }

  header .nav-btn:hover {
    background-color: transparent;
    color: var(--mainSecondaryColor);
  }
}