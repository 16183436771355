.full-btn {
  height: 100%;
  width: 100%;
}

.standard-outline-btn {
  background: none;
  color: var(--mainPrimaryColor);
  font-weight: 600;
  border: 2px solid var(--mainPrimaryColor);
  border-radius: 2rem;
}

.standard-btn {
  border-radius: 2rem;
  background-color: var(--mainPrimaryColor);
  border-color: var(--mainPrimaryColor);
  color: var(--primaryLightColor);
}

.standard-btn:disabled {
  background-color: var(--mainPrimaryColor);
  border-color: var(--mainPrimaryColor);
}

.standard-btn:hover, .standard-btn:focus, .standard-btn:first-child:active, :not(.btn-check)+.standard-btn:active, .standard-btn:focus-visible,
.standard-outline-btn:hover, .standard-outline-btn:focus, .standard-outline-btn:first-child:active, :not(.btn-check)+.standard-outline-btn:active, .standard-outline-btn:focus-visible {
  background-color: var(--mainSecondaryColor);
  border-color: var(--mainSecondaryColor);
  color: var(--primaryLightColor);
}

.light-btn {
  border-radius: 2rem;
  background-color: var(--primaryLightColor);
  border-color: var(--mainPrimaryColor);
  color: var(--secondaryDarkColor);
}

.light-btn:hover, .light-btn:focus, .light-btn:first-child:active, :not(.btn-check)+.light-btn:active, .light-btn:focus-visible {
  background-color: var(--mainSecondaryColor);
  border-color: var(--mainSecondaryColor);
  color: var(--primaryLightColor);
}

.light-btn:disabled {
  background-color: var(--primaryLightColor);
  border-color: var(--mainPrimaryColor);
  color: var(--secondaryDarkColor);
}

.cta-btn {
  width: 20rem;
  height: 3.3rem;
}