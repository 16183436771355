.card.display-as-table-entry {
  border: 0px;
  border-bottom: 1px solid var(--primaryBorderColor);
  border-radius: 0rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
}

.card.display-as-card {
  padding: 0.6rem;
}

.card-text {
  font-weight: 500;
  font-size: 0.875rem;
}

@media (max-width: 767.98px) {
  .card.display-as-table-entry {
    padding: .8rem;
  }
}